module.exports = {
  siteTitle: 'Suruthi Thaarani | Product Designer',
  siteDescription:
    'Suruthi Thaarani is a product designer based in Bangalore, IN. Specialized in UI/UX, visual design, consumer tech & SAAS products.',
  siteKeywords:
    'Suruthi Thaarani, Shruthi Thaarani, product designer, visual designer, adobe xd, figma, sketch, user experience, bangalore designer',
  siteUrl: 'https://is5.in',
  siteLanguage: 'en_US',
  googleAnalyticsID: 'UA-168736441-1',
  googleVerification: '',
  name: 'Suruthi Thaarani Rajendran',
  location: 'Bengaluru, India',
  email: 's@is5.in',
  twitterHandle: '@bchiang7',
  socialMedia: [
    {
      name: 'Dribbble',
      url: 'https://dribbble.com/isuruthi5',
    },
    {
      name: 'Behance',
      url: 'https://behance.net/suruthi'
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/suruthithaarani/',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/artbysuruthi',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/suruthithaarani',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Experience',
      url: '/#jobs',
    },
    {
      name: 'Work',
      url: '/#projects',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
  ],

  navHeight: 100,

  colors: {
    green: '#11adf3',
    navy: '#ffffff',
    darkNavy: '#ffffff',
  },

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
