import React from 'react';

const IconLogo = () => (
<svg width="241px" height="110px" viewBox="0 0 241 110" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>str</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard" transform="translate(-7.000000, -83.000000)" fill="#EFEFEF" fill-rule="nonzero">
            <path d="M50.4733945,193 C76.6678899,193 93,181.020339 93,161.868644 C93,148.233898 85.5834862,140.983051 67.9100917,137.436441 L48.2642202,133.495763 C42.2678899,132.313559 39.5853211,130.500847 39.5853211,127.505932 C39.5853211,123.644068 43.7669725,121.122034 50.1577982,121.122034 C56.8642202,121.122034 60.8880734,123.722881 61.440367,128.372881 L90.4752294,128.372881 C89.7651376,109.536441 76.1155963,100 49.7633028,100 C24.4366972,100 9.05137615,111.191525 9.05137615,129.633898 C9.05137615,143.899153 18.1247706,152.962712 36.0348624,156.588136 L52.9192661,160.055932 C59.2311927,161.316949 61.3614679,162.735593 61.3614679,165.730508 C61.3614679,169.513559 57.3376147,171.877966 50.8678899,171.877966 C43.4513761,171.877966 39.0330275,169.434746 37.9284404,164.548305 L7,164.548305 C8.65688073,183.305932 23.4110092,193 50.4733945,193 Z M150.160401,191 C156.220551,191 161.969925,190.448578 165,189.818381 L165,167.367615 C163.135338,167.603939 161.270677,167.761488 157.85213,167.761488 C151.170426,167.761488 147.984962,164.846827 147.984962,159.253829 L147.984962,127.586433 L165,127.586433 L165,104.032823 L147.984962,104.032823 L147.984962,83 L116.130326,83 L116.130326,104.032823 L103,104.032823 L103,127.586433 L116.130326,127.586433 L116.130326,166.343545 C116.130326,183.83151 125.764411,191 150.160401,191 Z M215.18599,190 L215.18599,145.968421 C215.18599,133.711404 222.957729,127.387719 234.968599,127.387719 C239.757246,127.387719 245.487923,128.168421 248,129.183333 L248,102.639474 C245.801932,101.546491 241.013285,101 236.852657,101 C226.097826,101 218.012077,108.182456 215.971014,117.082456 L214.400966,117.082456 L214.400966,102.249123 L183,102.249123 L183,190 L215.18599,190 Z" id="str"></path>
        </g>
    </g>
</svg>
);

export default IconLogo;
